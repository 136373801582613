import React from "react";
import { StaticQuery, graphql } from "gatsby";

import logoWhite from "../assets/images/logo-white.svg";
import funduszImg from "../assets/images/slides/fund_sprawiedliwosci-logo.png";
import ministerstwoImg from "../assets/images/slides/m_sprawiedliwosci_logo.png";

const Footer = () => (
    <StaticQuery
        query={graphql`
      query {
        strapiGlobal {
          siteName
        }
      }
    `}
        render={(data) => (
            <footer>
                <div className="border-line"></div>
                <div className="page-container">
                    <div className="banners">
                        <img src={ministerstwoImg} alt="Ministerstwo Sprawiedliwości"
                             width="238" height="67"/>
                        <img src={funduszImg} alt="Fundusz Sprawiedliwości"
                             width="269" height="74"/>
                    </div>
                    <p className="banners-text">Współfinansowano ze środków - Funduszu Sprawiedliwości, którego
                        dysponentem jest Minister Sprawiedliwości</p>
                </div>
                <div className="dark-bg">
                    <div className="page-container">
                        <img src={logoWhite} alt="Stop Przestępczości"/>
                        <div className="contact">
                            <p className="label">Kontakt</p>
                            <div className="contact__details">
                                <a href="mailto:redakcja@stopprzestepczosci.pl">redakcja@stopprzestepczosci.pl</a>
                                <a href="tel:695513059">tel. 695 513 059</a>
                            </div>
                        </div>
                        <ul className="links">
                            <li><a href="/files/Polityka_Prywatnosci_SP.pdf?v210513" target={'_blank'}>Polityka prywatności</a></li>
                            <li><a href="javascript: window.__tcfapi('displayConsentUi', 2, function() {} );" >Zmień ustawienia prywatności</a></li>
                            <li><a href="/files/Regulamin_SP.pdf?v210513" target={'_blank'}>Regulamin</a></li>
                        </ul>
                        <div className="copyright">&copy; 2021</div>
                    </div>
                </div>
            </footer>
        )}
    />
);

export default Footer;
