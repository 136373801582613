import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Navigation from "./navigation";
import Seo from "./seo";
import Footer from "./footer";

const Layout = ({ children, seo }) => (
    <StaticQuery
        query={graphql`
      query {
        strapiHomepage {
          seo {
            metaTitle
            metaDescription
            shareImage {
              publicURL
            }
          }
        }
      }
    `}
        render={(data) => (
            <>
                <Seo seo={seo} />
                <Navigation />
                <main>{children}</main>
                <Footer />
            </>
        )}
    />
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
