import React from "react";
import {graphql, Link, StaticQuery} from "gatsby";
import {Carousel, Navbar} from 'react-bootstrap';

import logo from "../assets/images/logo.svg";
import searchIcon from "../assets/images/search.svg";
import ministerstwoImg from "../assets/images/slides/m_sprawiedliwosci_logo.png";
import akademiaImg from "../assets/images/slides/stowarz_akademia_sportu-logo.png";
import funduszImg from "../assets/images/slides/fund_sprawiedliwosci-logo.png";


const Navigation = () => (
    <StaticQuery
        query={graphql`
      query {
        strapiGlobal {
          siteName
        }
        allStrapiCategory ( sort: {fields: id, order: ASC}) {
          edges {
            node {
              id
              slug
              name
              ordering
            }
          }
        }
      }
    `}
        render={(data) => (
            <header className="header-wrapper fixed-top">
                <div className="header-container">
                    <div className="page-container">
                        <Navbar collapseOnSelect expand="xl">
                            <div className="logo">
                                <Link to="/"><img className="logo-img" src={logo} alt="Stop Przestępczości"/></Link>
                            </div>
                            <div className="sponsors-lg-container">
                                <div className="sponsors-lg">
                                    <a href="https://www.gov.pl/web/sprawiedliwosc" ><img src={ministerstwoImg} className="img-ministerstwo" alt="Ministerstwo Sprawiedliwości" /></a>
                                    <a href="https://www.funduszsprawiedliwosci.gov.pl/" ><img src={funduszImg} className="img-fundusz" alt="Fundusz Sprawiedliwości" /></a>
                                    <a href="http://stowarzyszenieakademiasportu.pl/" ><img src={akademiaImg} className="img-stowarzyszenie" alt="Stowarzyszenie Akademia Sportu" /></a>
                                    <span className="sponsors-title">Partnerzy Serwisu</span>

                                </div>
                                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                            </div>
                            <div className="sponsors sponsors-mobile">
                                <Carousel interval={4000} className="partners" controls={false} indicators={false}>
                                    <Carousel.Item>
                                        <a className="partners-box" href="https://www.gov.pl/web/sprawiedliwosc" ><img src={ministerstwoImg} className="img" alt="Ministerstwo Sprawiedliwości" /></a>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <a className="partners-box" href="http://stowarzyszenieakademiasportu.pl/" ><img src={akademiaImg} className="img" alt="Stowarzyszenie Akademia Sportu" /></a>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <a className="partners-box" href="https://www.funduszsprawiedliwosci.gov.pl/" ><img src={funduszImg} className="img" alt="Fundusz Sprawiedliwości" /></a>
                                    </Carousel.Item>
                                </Carousel>
                                <span className="sponsors-title">Partnerzy Serwisu</span>
                            </div>

                            <Navbar.Collapse id="responsive-navbar-nav">
                                <ul className="navbar-nav">
                                    {data.allStrapiCategory.edges.sort((a,b) => {
                                        if (a.node.ordering && b.node.ordering) {
                                            return a.node.ordering - b.node.ordering;
                                        } else {
                                            return a.node.createdAt - b.node.createdAt;
                                        }
                                    }).map((category, i) => (
                                        <li key={`category__${category.node.slug}`}>
                                            <Link activeClassName="active" partiallyActive={true} to={`/category/${category.node.slug}`}>
                                                {category.node.name}
                                            </Link>
                                        </li>     ))}
                                        <li className="visible-on-lg">
                                            <Link to={`/search`}>
                                                <img src={searchIcon} className="other-search" alt="Szukaj" />
                                            </Link>
                                        </li>
                                </ul>
                            </Navbar.Collapse>
                            <div className="sponsors">
                                <Carousel interval={4000} className="partners" controls={false} indicators={false}>
                                    <Carousel.Item>
                                        <a className="partners-box" href="https://www.gov.pl/web/sprawiedliwosc" ><img src={ministerstwoImg} className="img" alt="Ministerstwo Sprawiedliwości" /></a>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <a className="partners-box" href="http://stowarzyszenieakademiasportu.pl/" ><img src={akademiaImg} className="img" alt="Stowarzyszenie Akademia Sportu" /></a>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <a className="partners-box" href="https://www.funduszsprawiedliwosci.gov.pl/" ><img src={funduszImg} className="img" alt="Fundusz Sprawiedliwości" /></a>
                                    </Carousel.Item>
                                </Carousel>
                                <span className="sponsors-title">Partnerzy Serwisu</span>
                            </div>
                            <Link to={`/search`} className="search-icon search-icon-xl">
                                <img src={searchIcon} className="other-search" alt="Szukaj" />
                            </Link>
                        </Navbar>

                    </div>
                    <div className="header-black-item"></div>

                </div>

            </header>
        )}
    />
);

export default Navigation;
